import type React from 'react'

import { CommerceToolsApi } from '@grandvisionhq/commercetools'
import type { ProductDetailProvider } from '@grandvisionhq/products'

type SpecificationConfiguration = React.ComponentPropsWithoutRef<
  typeof ProductDetailProvider
>['specificationConfiguration']

export function getSpecifications(productType: string): SpecificationConfiguration | undefined {
  if (CommerceToolsApi.isSunglasses(productType)) {
    return [
      {
        labelKey: 'products.specifications.brand',
        attribute: 'brand',
      },
      {
        labelKey: 'products.specifications.frameColor',
        attribute: 'frameColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.lensColor',
        attribute: 'lensColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameShape',
        attribute: 'frameShape',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameMaterial',
        attribute: 'frameMaterial',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.gender',
        attribute: 'gender',
        isLocalised: true,
      },
      {
        labelKey: 'products.sunglasses.features.polarized',
        attribute: 'polarized',
      },
      {
        labelKey: 'products.specifications.roadworthiness',
        attribute: 'roadworthiness',
        labelPrefix: 'products.specifications.roadworthiness.',
      },
    ]
  }
}
